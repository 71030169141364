

// const CHAIN_KEY = process.env['NX_CHAIN'] as keyof typeof wagmiChains;
// export const CHAIN = wagmiChains[CHAIN_KEY] as Chain;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID as string;
export const JSON_RPC_PROVIDER = process.env.REACT_APP_JSON_RPC_PROVIDER_URL as string;
export const ANYONE_TOKEN = process.env.REACT_APP_TOKEN_APP as `0x${string}`;
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS as `0x${string}`;
export const SUPABASE_API_KEY = process.env.REACT_APP_SUPABASE_API_KEY as string;
export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL as string;
// export const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY as string;
export const NOTNEEDED = process.env.REACT_APP_NOT_NEEDED as `0x${string}`;
export const MAINNET_RPC_PROVIDER = process.env.REACT_APP_MAINNET_JSON_RPC_PROVIDER_URL as string;
export const SEPOLIA_RPC_PROVIDER = process.env.REACT_APP_JSON_RPC_PROVIDER_URL as string;
export const MAINNET_ANYONE_TOKEN = process.env.REACT_APP_MAINNET_TOKEN as `0x${string}`;
export const CLOUD = process.env.REACT_APP_CLOUD as string;